import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Card, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NoResult } from "./NoResult";
import { token } from "../TokenProvider";
import { TestApi } from "../endpoints";
import { TestResult } from "../shared/Models";
import { Link } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);
type Build = {
  reponame: string;
  buildid: number;
  branchName: string
};

const commonStyles = {
  m: 1,
  border: 1,
  width: "1rem",
  height: "1rem",
};

function GetLink(props: Build) : string{
  if(props.branchName.toLowerCase().includes("/"))
  {
    return `${props.branchName.replace("/","-")}/${props.reponame}/${props.buildid}`;
  }
  return `${props.branchName}/${props.reponame}/${props.buildid}`;
}

export const PieChartComponent = (
  props: Build,
  { labels = ["Passed", "Failed", "Not Executed"] }
) => {
  const [testresult, setTestResult] = useState<TestResult>();

  useEffect(() => {
    fetch(`${TestApi}pipelineId=${props.buildid}&metricNames=1`, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa("" + ":" + token),
      },
    })
      .then((response) => response.json())
      .then((response) => setTestResult(response));
  }, [props.buildid]);
  if (testresult === undefined) {
    return <NoResult />;
  } else if (testresult.resultSummary == null) {
    return (
      <Paper sx={{ width: "250px", Height: "100px" }} elevation={0}>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            fontFamily: "Segoe UI VSS (Regular)",
          }}
        >
          {props.reponame}
        </Typography>
        No Test Result Yet!
        <NoResult />
      </Paper>
    );
  } else {
    return (
      <Paper sx={{ width: "250px", Height: "100px" }} elevation={0}>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            fontFamily: "Segoe UI VSS (Regular)",
          }}
        >
          {props.reponame}
        </Typography>
        <Pie
          data={{
            labels: labels,
            datasets: [
              {
                data: [
                  testresult?.resultSummary.resultSummaryByRunState.Completed
                    .aggregatedResultDetailsByOutcome.Passed?.count ===
                  undefined
                    ? 0
                    : testresult?.resultSummary.resultSummaryByRunState
                        .Completed.aggregatedResultDetailsByOutcome.Passed
                        ?.count,
                  testresult?.resultSummary.resultSummaryByRunState.Completed
                    .aggregatedResultDetailsByOutcome.Failed?.count ===
                  undefined
                    ? 0
                    : testresult?.resultSummary.resultSummaryByRunState
                        .Completed.aggregatedResultDetailsByOutcome.Failed
                        ?.count,
                  testresult?.resultSummary.resultSummaryByRunState.Completed
                    .aggregatedResultDetailsByOutcome.NotExecuted?.count ===
                  undefined
                    ? 0
                    : testresult?.resultSummary.resultSummaryByRunState
                        .Completed.aggregatedResultDetailsByOutcome.NotExecuted
                        ?.count,
                ],
                backgroundColor: ["#107C10", "#DA0A00", "#FFA500"],
              },
            ],
          }}
        />
        <Typography
          sx={{
            fontSize: 14,
            fontFamily: "Segoe UI VSS (Regular)",
          }}
        >
          Time taken : 
          {(testresult?.resultSummary.resultSummaryByRunState.Completed.duration).substring(0,8)}
        </Typography>

        <Typography
          sx={{
            fontSize: 18,
            fontWeight: "bold",
            fontFamily: "Segoe UI VSS (Regular)",
          }}
        >
          Total Tests:
          {testresult?.resultSummary.resultSummaryByRunState.Completed
            .totalTestCount === undefined
            ? 0
            : testresult?.resultSummary.resultSummaryByRunState.Completed
                .totalTestCount}
        </Typography>

        <Paper sx={{ marginTop: "5px" }}>
          <Card>
            <Card sx={{ display: "flex" }} elevation={0}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: "50%",
                  backgroundColor: "#107C10",
                  borderColor: "#107C10",
                }}
              />
              <Typography>
                Passed:
                {testresult?.resultSummary.resultSummaryByRunState.Completed
                  .aggregatedResultDetailsByOutcome.Passed?.count === undefined
                  ? 0
                  : testresult?.resultSummary.resultSummaryByRunState.Completed
                      .aggregatedResultDetailsByOutcome.Passed?.count}
              </Typography>
            </Card>
            <Card sx={{ display: "flex" }} elevation={0}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: "50%",
                  backgroundColor: "#DA0A00",
                  borderColor: "#DA0A00",
                }}
              />
              <Typography>
               <Link to={`failedtests/${GetLink(props)}`} color="black">Failed:
                 {testresult?.resultSummary.resultSummaryByRunState.Completed
                   .aggregatedResultDetailsByOutcome.Failed?.count === undefined
                   ? 0
                   : testresult?.resultSummary.resultSummaryByRunState.Completed
                       .aggregatedResultDetailsByOutcome.Failed?.count}</Link>
              </Typography>
            </Card>
            <Card sx={{ display: "flex" }} elevation={0}>
              <Box
                sx={{
                  ...commonStyles,
                  borderRadius: "50%",
                  backgroundColor: "#FFA500",
                  borderColor: "#FFA500",
                }}
              />
              <Typography>
              <Link to={`notexecutedtests/${GetLink(props)}`} color="black">Not Executed:
                {testresult?.resultSummary.resultSummaryByRunState.Completed
                  .aggregatedResultDetailsByOutcome.NotExecuted?.count ===
                undefined
                  ? 0
                  : testresult?.resultSummary.resultSummaryByRunState.Completed
                      .aggregatedResultDetailsByOutcome.NotExecuted?.count}</Link>
              </Typography>
            </Card>
          </Card>
          <Card></Card>
        </Paper>
      </Paper>
    );
  }
};
