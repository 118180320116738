import { Box, Card, Paper, Typography } from "@mui/material";
import BasicCard from "./BasicCard";

export const Dashboard = (props: { branchname: string }) => {
  return (
    <div>
      <Box>
        <Typography sx={{ fontSize: 20, fontWeight: "bold", color: "white", backgroundColor: "#003130"}}>
          UNIT TEST REPORT ({props.branchname})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "5px",
          marginBottom: "5px",
          boxShadow: 2,
        }}
      >
        <BasicCard branchname={props.branchname} repoid={52} />
        <BasicCard branchname={props.branchname} repoid={9} />
        <BasicCard branchname={props.branchname} repoid={25} />
        <BasicCard branchname={props.branchname} repoid={23} />
      </Box>
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: "bold",
          fontFamily: "Segoe UI VSS (Regular)",
          color: "white",
          backgroundColor: "#003130"
        }}
      >
        API TEST REPORT ({props.branchname})
      </Typography>
      <Card
        sx={{
          display: "flex",
          marginTop: "5px",
          marginBottom: "5px",
          boxShadow: 2,
        }}
      >
        <BasicCard branchname={props.branchname} repoid={57} />
        <BasicCard branchname={props.branchname} repoid={56} />
        <BasicCard branchname={props.branchname} repoid={139} />
        <BasicCard branchname={props.branchname} repoid={102} />
      </Card>
    </div>
  );
};
