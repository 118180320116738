import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { NotExecutedTestResultList, NotExecutedTests } from '../shared/NotExecutedTestsModel';
import { NotExcutedTestApi, NotExecutedTestDetailsApi } from '../endpoints';
import { token } from '../TokenProvider';
import { Result, TestRequestBody, TestRun } from '../shared/Models';
import { useParams } from 'react-router-dom';
import { PageDoesNotExist, ZeroNotExecuted } from './NoResult';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#003130",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

let headers = new Headers();
headers.append("Authorization", "Basic " + btoa("" + ":" + token));
headers.append("Accept", "application/json;api-version=5.2-preview.1;excludeUrls=true;enumsAsNumbers=true;msDateFormat=true;noArrayWrap=true");
headers.append("Content-Type", "application/json");

const divStyle = {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    color: '#003130',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'revert',
  };

export default function NotExecutedTestTable() {
    const { branchName, reponame, buildid } = useParams();
    const [NotExecutedTests, setNotExecutedTests] = React.useState<NotExecutedTests>();
    const [NotExcecutedTestResultList, setNotExcecutedTestResultList] = React.useState<NotExecutedTestResultList>();

    React.useEffect(() => {
        fetch(`${NotExcutedTestApi}${buildid}&publishContext=CI&groupBy=TestRun&%24filter=Outcome%20eq%20NotExecuted&%24orderby=&shouldIncludeResults=true&queryRunSummaryForInProgress=false`, {
          method: "GET",
          headers: {
            Authorization: "Basic " + btoa("" + ":" + token),
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if(response.resultsForGroup.length==2)
              response.resultsForGroup[0]=response.resultsForGroup[1];// <-- Debug: Check the response structure here
            setNotExecutedTests(response);
        });
      }, []);

      React.useEffect(() => {
        if (NotExecutedTests?.resultsForGroup[0] !== undefined && NotExecutedTests?.resultsForGroup[0].results.length > 0) {
        fetch(`${NotExecutedTestDetailsApi}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(GetFailedTestRequestBody(NotExecutedTests)),
          })
          .then((failedresponse) => failedresponse.json())
          .then((failedresponse) => setNotExcecutedTestResultList(failedresponse));
        }
      }, [NotExecutedTests]);

      if(NotExecutedTests !== undefined && NotExecutedTests?.resultsForGroup.length == 0){
        return(<PageDoesNotExist/>);
      }

      else if(NotExecutedTests?.resultsForGroup[0] !== undefined && NotExecutedTests?.resultsForGroup[0].results.length == 0)
      {
        return(<ZeroNotExecuted/>);
      }

      else{
        return (
            <div>
                <div style={divStyle}>
                        {branchName}&nbsp;&nbsp;{reponame}&nbsp;&nbsp;Not Executed Tests
                    </div>
                <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell sx={{paddingLeft: "40px"}}>Not Executed Tests</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {NotExcecutedTestResultList?.results.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row" sx={{paddingLeft: "40px"}}>
                        {row.testCaseTitle}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
          );
      }
}

function GetFailedTestRequestBody(response: any): TestRequestBody{
    let fiellds = ["Outcome","TestCaseTitle","AutomatedTestName","AutomatedTestStorage","TestResultGroupType","Duration","ReleaseEnvId","Owner","FailingSince","DateStarted","DateCompleted","OutcomeConfidence","IsTestResultFlaky","TestResultFlakyState", "errorMessage", "stackTrace"]
        let NotExecutedTestRequestBody = {} as TestRequestBody;
        NotExecutedTestRequestBody.fields = fiellds;
        let results =  [] as Result[];

    response.resultsForGroup[0].results.forEach((element: { testRun: { id: string; }; id: number; }) => {
        let testRun = {} as TestRun;
        let result = {} as Result;
        testRun.id = element.testRun.id;
        result.id = element.id;
        result.testRun = testRun;
            results.push(result);
    });
    NotExecutedTestRequestBody.results = results;
    return NotExecutedTestRequestBody;
}