import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import React, { useEffect, useState } from "react";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorDetails from './ErrorDetails';
import { FailedTestApi, TestApi } from '../endpoints';
import { TestRequestBody, FailedTestResult, FailedTestResultList, Result, TestResult, TestRun } from '../shared/Models';
import { token } from '../TokenProvider';
import { useParams } from 'react-router-dom';
import { NoTestFailure, PageDoesNotExist } from './NoResult';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function millisecondsToTime(milliseconds: number): string {
    const date = new Date(milliseconds);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');
  const millisecondsStr = String(date.getUTCMilliseconds()).padStart(3, '0');
  return `${hours}:${minutes}:${seconds}.${millisecondsStr}`;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#003130",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
function Row(props: { row: FailedTestResult }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell sx={{padding: "8px"}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {row.testCaseTitle}
        </StyledTableCell>
        <StyledTableCell align="left">{millisecondsToTime(row.durationInMs)}</StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ErrorDetails key={row.id} row={row}/>
            </Box>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </React.Fragment>
  );
}

let headers = new Headers();
headers.append("Authorization", "Basic " + btoa("" + ":" + token));
headers.append("Accept", "application/json;api-version=5.2-preview.1;excludeUrls=true;enumsAsNumbers=true;msDateFormat=true;noArrayWrap=true");
headers.append("Content-Type", "application/json");

function GetFailedTestRequestBody(response: any): TestRequestBody{
        let fiellds = ["Outcome","TestCaseTitle","AutomatedTestName","AutomatedTestStorage","TestResultGroupType","Duration","ReleaseEnvId","Owner","FailingSince","DateStarted","DateCompleted","OutcomeConfidence","IsTestResultFlaky","TestResultFlakyState", "errorMessage", "stackTrace"]
        let failedTestRequestBody = {} as TestRequestBody;
        failedTestRequestBody.fields = fiellds;
        let results =  [] as Result[];
        response.resultsAnalysis.testFailuresAnalysis.newFailures.testResults.forEach((element: { testRunId: string; testResultId: number; }) => {
            let testRun = {} as TestRun;
            let result = {} as Result;
            testRun.id = element.testRunId;
            result.id = element.testResultId;
            result.testRun = testRun;
            results.push(result);
        });

        response.resultsAnalysis.testFailuresAnalysis.existingFailures.testResults.forEach((element: { testRunId: string; testResultId: number; }) => {
            let testRun = {} as TestRun;
            let result = {} as Result;
            testRun.id = element.testRunId;
            result.id = element.testResultId;
            result.testRun = testRun;
            results.push(result);
        });
        failedTestRequestBody.results = results;
        return failedTestRequestBody;
}
const divStyle = {
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    color: '#003130',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'revert',
  };

export default function FailedTestTable() {
    const { branchName, reponame, buildid } = useParams();
    const [testresult, setTestResult] = useState<TestResult>();
    const [failedTestResultList, setFailedTestResult] = useState<FailedTestResultList>();
    const [copyMessage, setCopyMessage] = useState("Copy to Clipboard");

  useEffect(() => {
    fetch(`${TestApi}pipelineId=${buildid}&metricNames=1`, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa("" + ":" + token),
      },
    })
      .then((response) => response.json())
      .then((response) => setTestResult(response));
  }, []);

  useEffect(() => {
    if (testresult !== undefined 
        && testresult.resultsAnalysis != null
        && (testresult.resultsAnalysis.testFailuresAnalysis.existingFailures.count > 0 || testresult?.resultsAnalysis.testFailuresAnalysis.newFailures.count > 0)) {
    fetch(`${FailedTestApi}`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(GetFailedTestRequestBody(testresult)),
      })
      .then((failedresponse) => failedresponse.json())
      .then((failedresponse) => setFailedTestResult(failedresponse));
    }
  }, [testresult]);

  //let count = testresult.
  if(testresult !== undefined && testresult.resultsAnalysis == null){
    return(<PageDoesNotExist/>);
  }
  else if(testresult !== undefined 
    && (testresult.resultsAnalysis.testFailuresAnalysis.existingFailures.count == 0 
        && testresult?.resultsAnalysis.testFailuresAnalysis.newFailures.count == 0))
  {
    return(<NoTestFailure/>);
  }
  else{
    const handleClick = () => {
      const failedTestResults = failedTestResultList?.results;
      setCopyMessage("Copied")
      if (!failedTestResults || failedTestResults.length === 0) {
        alert('No test results to copy.');
        return;
      }
    
      const testNames = failedTestResults.map(element => element.testCase.name);
      const textToCopy = testNames.join(',');
    
      const tempTextArea = document.createElement('textarea');
      tempTextArea.textContent = textToCopy;
      document.body.appendChild(tempTextArea);
    
      try {
        tempTextArea.select();
        document.execCommand('copy');
        
      } catch (err) {
        alert('Failed to copy to clipboard. Please copy manually.');
      } finally {
        document.body.removeChild(tempTextArea);
      }
    };

    return (
        <div>
            <div style={divStyle} >
                {branchName}&nbsp;&nbsp;{reponame}&nbsp;&nbsp;Failed Tests &nbsp;&nbsp;
                <Tooltip title="COPY FAILED TEST CASES">
                  <Button variant="contained" onClick={handleClick}>{copyMessage}</Button>
                </Tooltip>
            </div>
            
            <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell />
                            <StyledTableCell align="left" sx={{fontSize: "18px", fontWeight: 600}}>Tests</StyledTableCell>
                            <StyledTableCell align="left" sx={{fontSize: "18px", fontWeight: 600}}>Duration (HH:MM:SS:MS)</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {failedTestResultList?.results.map((row) => (
                            <Row key={row.id} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
      );
  }
}
