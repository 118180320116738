import "./App.css";
import { HomePage } from "./Pages/HomePage";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import FailedTestTable from "./Components/FailedTestTable";
import NotExecutedTestTable from "./Components/NotExecutedTestTable";
function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="failedtests/:branchName/:reponame/:buildid" element={<FailedTestTable/>}/>
          <Route path="notexecutedtests/:branchName/:reponame/:buildid" element={<NotExecutedTestTable/>}/>
        </Routes>      
    </div>
  );
}

export default App;
