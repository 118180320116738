import { Dashboard } from "../Components/Dashboard";
import SelectLabels from "../Components/DropDown";

export const HomePage = () => {
  return (
    <div>
      <SelectLabels />
    </div>
  );
};
