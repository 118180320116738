export const BuildApi =
  "https://dev.azure.com/proarc/Products/_apis/build/latest";
export const TestApi =
  "https://vstmr.dev.azure.com/proarc/Products/_apis/testresults/metrics?";
export const BrancheApi =
  "https://dev.azure.com/proarc/Products/_apis/git/repositories/proarcapi/refs?filter=heads&%24top=200";
export const FailedTestApi = 
  "https://vstmr.dev.azure.com/proarc/Products/_apis/testresults/results";
export const NotExcutedTestApi = 
  "https://vstmr.dev.azure.com/proarc/Products/_apis/testresults/resultdetailsbybuild?buildId=";
export const NotExecutedTestDetailsApi = 
  "https://vstmr.dev.azure.com/proarc/Products/_apis/testresults/results";
