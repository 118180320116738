import { Typography } from '@mui/material'
import img from '../Resources/NoResults.svg'
import NoTestFailed from '../Resources/no-failed-tests.svg'

export const NoResult = () => {
    return(
        <div style={{marginTop:'100px'}}>
            <img src={img} alt="No Results" />
        </div>
    )
}

export const PageDoesNotExist = () => {
    return(
        <div style={{marginTop:'100px'}}>
            <img src={img} alt="No Results" />
            <Typography variant='h5'>The page you are looking for does not exist!</Typography>
        </div>
    )
}

export const NoTestFailure = () => {
    return(
        <div style={{marginTop:'100px'}}>
            <img src={NoTestFailed} alt="No Results" style={{width: "25%"}}/>
            <Typography variant='h5'>Hooray! There are no test failures.</Typography>
        </div>
    )
}

export const ZeroNotExecuted = () => {
    return(
        <div style={{marginTop:'100px'}}>
            <Typography variant='h5'>Hooray! There are no not executed tests.</Typography>
        </div>
    )
}
