import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useEffect, useState } from "react";
import { NoResult } from "./NoResult";
import { PieChartComponent } from "./PieChartComponent";
import { token } from "../TokenProvider";
import { BuildApi } from "../endpoints";
import { Pipeline } from "../shared/Models";
import Typography from "@mui/material/Typography";
import { Paper } from "@mui/material";

const BasicCardStyle = {
  display: "flex",
  margin: "0 auto",
};

type Branch = {
  branchname: string;
  repoid: number;
};

export default function BasicCard(props: Branch) {
  const [pipline, setpipeline] = useState<Pipeline>();
  useEffect(() => {
    fetch(
      `${BuildApi}/${props.repoid}?branchName=${props.branchname}&api-version=7.0-preview.1`,
      {
        method: "GET",
        headers: {
          Authorization: "Basic " + btoa("" + ":" + token),
        },
      }
    )
      .then((response) => response.json())
      .then((response) => setpipeline(response));
  }, [props.branchname]);
  if (pipline === undefined || pipline.definition == null) {
    return (
      <Card sx={BasicCardStyle} elevation={0}>
        <CardContent>
          <div style={{ display: "flex" }}>
            <Paper sx={{ width: "250px", Height: "100px" }} elevation={0}>
              No Pipeline...!
              <NoResult />
            </Paper>
          </div>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card sx={BasicCardStyle} elevation={0}>
        <CardContent>
          <div style={{ display: "flex" }}>
            <PieChartComponent
              buildid={pipline.id}
              reponame={pipline.definition.name}
              branchName={props.branchname}
            />
          </div>
        </CardContent>
      </Card>
    );
  }
}
