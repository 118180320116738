import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BrancheApi } from "../endpoints";
import { token } from "../TokenProvider";
import { Branches } from "../BranchesModel";
import { Dashboard } from "./Dashboard";
import { Box, Paper, Typography } from "@mui/material";

export default function SelectLabels() {
  const [branches, setBranches] = React.useState<Branches>();
  const [selected, setSelectedBranch] = React.useState("master");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedBranch(event.target.value);
  };

  React.useEffect(() => {
    fetch(BrancheApi, {
      method: "GET",
      headers: {
        Authorization: "Basic " + btoa("" + ":" + token),
      },
    })
      .then((response) => response.json())
      .then((response) => setBranches(response));
  }, []);

  if (branches)
    return (
      <div>
        <Paper sx={{ display: "flex", height: "60px" }}>
          <Box
            sx={{
              float: "left",
              width: "33.33%",
              padding: "5px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
              Date : {new Date().toLocaleDateString()}
            </Typography>
          </Box>
          <Box
            sx={{
              float: "left",
              width: "33.33%",
              padding: "5px",
              border: 1,
              borderRadius: 25,
            }}
          >
            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", alignItems: "center" }}
            >
              Test Result Report For Master And Maintenance Branches
            </Typography>
          </Box>
          <Box
            sx={{
              float: "right",
              width: "33.33%",
              padding: "5px",
              height: "50px",
              alignItems: "center",
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 150, height: "50px" }}>
              <InputLabel id="demo-simple-select-helper-label">
                Branch
              </InputLabel>
              <Select
                sx={{
                  m: 1,
                  minWidth: 80,
                  maxHeight: 200,
                  height: "50px",
                  overflow: "auto",
                }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selected}
                label="Branch"
                onChange={handleChange}
              >
                <MenuItem value="master">master</MenuItem>
                {branches.value.map((item: any) => {
                  if (item.name.toLowerCase().includes("hotfix"))
                    return (
                      <MenuItem value={item.name.replace("refs/heads/", "")}>
                        {item.name.replace("refs/heads/", "")}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Box>
        </Paper>

        <Dashboard branchname={selected} />
      </div>
    );
  else
    return (
      <div>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="demo-simple-select-helper-label">Banch</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={selected}
            label="Branch"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>master</em>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );
}
